import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import "./styles/index.scss";

const SelectComponent = ({ getSelect, value = "", options }) => {
  const [inputValue, setInputValue] = useState(value);

  const handleChange = useCallback(
    (value) => {
      setInputValue(value);
      getSelect(value);
    },
    [getSelect]
  );

  useEffect(() => {
    if (options.length === 1) {
      handleChange(options[0]);
    }

    if (value) {
      const defautlVal = options.find(val => val.value === value);
      handleChange(defautlVal || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  return (
    <Select
      className="payments-select"
      options={options}
      placeholder=""
      onChange={handleChange}
      value={inputValue}
    />
  );
};

export default SelectComponent;
