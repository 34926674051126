import * as Yup from 'yup';

import Constants from '../../utils/constants';

const { INPUTS_MAX_LENGTH } = Constants;

export const FIELDS_NAME = {
  CRYPTO_RED: 'cryptoRed',
  CPF: 'cpf',
  CURRENCY: 'currency',
  AMOUNT: 'amount',
};

export const initialValues = {
  [FIELDS_NAME.CRYPTO_RED]: '',
  [FIELDS_NAME.CPF]: '',
  [FIELDS_NAME.CURRENCY]: '',
  [FIELDS_NAME.AMOUNT]: null,
};

export const getCryptoRedData = (cryptoRed) => {
  const data = cryptoRed ? cryptoRed.split("-") : []

  return {
    cryptoName: data?.[0] ?? '',
    cryptoNetworkName: data?.[1] ?? '',
    cryptoNetworkId: data?.[2] ?? '',
  }
}

export const isBRLPixCryptoRed = (cryptoRed) => {
  const { cryptoName, cryptoNetworkName } = getCryptoRedData(cryptoRed);

  return cryptoName === 'BRL' && cryptoNetworkName === 'PIX';
};

export const validationSchema = () =>
  Yup.object().shape({
    [FIELDS_NAME.CRYPTO_RED]: Yup.string().required(),
    [FIELDS_NAME.CPF]: Yup.string().when(FIELDS_NAME.CRYPTO_RED, {
      is: (currentVal) => isBRLPixCryptoRed(currentVal),
      then: (schema) => schema.required().min(INPUTS_MAX_LENGTH.CPF).max(INPUTS_MAX_LENGTH.CPF),
      otherwise: (schema) => schema.notRequired()
    }),
    [FIELDS_NAME.CURRENCY]: Yup.string().required(),
    [FIELDS_NAME.AMOUNT]: Yup.number().nullable().required()
  });

