import React, { useEffect, useState } from 'react';
import WalletConnectQRCodeModal from "@walletconnect/qrcode-modal";
import { useSelector, useDispatch } from 'react-redux';
import Web3 from 'web3';
import ERC20ABI from 'erc-20-abi';

import PaymentsConsiderations from '../../components/PaymentsConsidetarions';
import QRCodeGenerator from '../../components/QrCode';
import CheckingPayment from '../../components/checkingPayment';
import PartialPay from '../../components/partialPayDisclaimer';
import Card from '../../components/Card';
import Button from '../../components/button';
import CardPaymentHeader from '../../components/cardPaymentHeader';
import Footer from '../../components/footer';
import Snackbar from '../../components/snackbar';
import LoginModal from '../../components/LoginModal';
import Rewards from '../../components/Rewards';
import Spinner from '../../components/spinner';
import { useQuery } from '../../hooks';
import { openConection, connector } from '../../utils/walletConnect';
import Constants from '../../utils/constants';
// import { calculateERC20Amount } from '../../utils/helpers';
import { getInvoiceData, getInvoiceStatus, saveHash } from '../../redux/actionsCreators';
import { setSnackbar, setModalLoginShow } from '../../redux/actions/payments';

import './styles/index.scss';
import { useCallback } from 'react';

const { INVOICE_STATUS } = Constants;

const Pay = () => {
  const [wallet, setWallet] = useState(null);
  const dispatch = useDispatch();
  const { invoice, invoiceStatus, modalLogin, loadingCreateReward } = useSelector(state => state.Payments);
  const query = useQuery();
  const token = query.get("token");
  const web3 = new Web3(Web3.givenProvider || "ws://localhost:3000");
  const { toWei, toHex } =  web3.utils;

  const logout = () => {
    if (typeof window === 'object') {
      window.localStorage.removeItem("walletconnect");
      setWallet(null);
      window.location.reload();
    }
  }

  const verifyState = () => {
    const walletData = window.localStorage.getItem("walletconnect")
    if (walletData) {
      const walletDataJson = JSON.parse(walletData)
      setWallet(walletDataJson)
      WalletConnectQRCodeModal.close()
    }
  }

  useEffect(() => {
    if (token) {
      dispatch(getInvoiceData(token))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!wallet) {
      verifyState()
    }
  })

  useEffect(() => {
    const getData = async () => {
      if (typeof window === 'object') {
        if (wallet && Object.keys(invoice).length) {
          const {
            native_token: nativetoken,
            denomitation,
            smart_contract: smartContract,
            decimals_places: decimalPlaces,
            // chain_id: chainId,
          } = invoice?.data_to_wallet_connect

          let contract = null;
          let transacctionData = null;

          if (smartContract) {
            contract = new web3.eth.Contract(ERC20ABI, smartContract);
            let amountParsed = toWei(invoice?.amount, denomitation)
            amountParsed = amountParsed.toString().substr(0, decimalPlaces)

            //calculate base Amount
            // const baseAmount = calculateERC20Amount(toWei(invoice?.amount), decimalPlaces)
            // Create the transaction data
            transacctionData = contract.methods.transfer(
                invoice?.to.toLowerCase(),
                amountParsed
              ).encodeABI();
          }

          let response = {}

          const tx = {
            from: wallet.accounts[0],
            to: Boolean(!nativetoken && smartContract) ? smartContract : invoice?.to,
            data: Boolean(!nativetoken && transacctionData) ? transacctionData : '0x', // Required
            //value: nativetoken ? toHex(toWei(invoice?.amount, denomitation)) : "",
          };
          if (nativetoken) {
            tx.value = toHex(toWei(invoice?.amount, denomitation))
          }

          // @ TODO: Check before calculate gas and gas price
          // const gasLimit = await web3.eth.estimateGas(tx);
          // const gasPrice = await web3.eth.getGasPrice();
          // console.log('Gas Limit:', gasLimit);
          // console.log('Gas Price:', gasPrice);

          // tx.gas = gasLimit;
          // tx.gasPrice = gasPrice;
          // Send transaction
          try {
            const result = await connector.sendTransaction(tx)
             // Returns transaction id (hash)
            let message = "Transaccion exitosa!";

            if (result === null) {
              message = "Transaccion rechazada!"
            } else {
              dispatch(saveHash(result, token))
            }
            response = {
              message,
              open: true,
              severity: result === null ? "error" : "success",
            }
            
          } catch(error) {
            // Error returned when rejected
            console.error(error);
            response = {
              message: "Ocurrio un error!",
              open: true,
              severity: "error",
            };
          }

          dispatch(setSnackbar(response));
          setTimeout(() => {
            logout()
          }, 3000)
        }
      }
    }
    getData();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice, wallet, token])

  useEffect(() => {
    if(token && (invoiceStatus === INVOICE_STATUS.WAITING ||
      invoiceStatus === INVOICE_STATUS.CHECKING)) {
      pingStatus()
    } else {
      processInvoiceStatus()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceStatus])

  const pingStatus = () => {
    setInterval(() => {
      dispatch(getInvoiceStatus(token))
    }, 5000)
  }

  const processInvoiceStatus = () => {
    switch(invoiceStatus) {
      case INVOICE_STATUS.COMPLETING:
      case INVOICE_STATUS.PRE_CONFIRMED:
        window.location.assign(invoice?.success_url)
        break;
      case INVOICE_STATUS.EXPIRING:
      case INVOICE_STATUS.PAID_PARTIAL_AND_EXPIRED:
        window.location.assign(invoice?.cancel_url)
        break;
      case INVOICE_STATUS.PAID_PARTIAL:
        window.location.reload();
        break;
      default:
        break;
    }
  }

  const openBTCWallet = () => window.location.assign(invoice?.data_to_render?.to_and_amount);
  const backButton = () => window.location.assign(invoice?.cancel_url)
  const openWalletConnect = () => openConection();

  const handleOpenModalLogin = useCallback(
    () => {
      dispatch(setModalLoginShow(true));
    },
    [dispatch],
  )

  return (
    <>
      <Snackbar />

      <div className="pay-container">
        <PaymentsConsiderations />

        <Card className="card-container">
          {invoice?.debts && <PartialPay />}

          <CardPaymentHeader
            amount={invoice?.data_to_render?.amount}
            symbol={invoice?.currency?.toUpperCase()}
            icon={invoice?.currency_logo}
          />

          <QRCodeGenerator
            qr={invoice?.data_to_qr}
            address={invoice?.data_to_render?.to}
          />

          <CheckingPayment />

          {invoice?.config?.pay_with_reward && Boolean(modalLogin.currentUser.token) && (
            <Rewards invoiceToken={token} />
          )}

          <div className="card-container__buttons-content">
            {invoice?.config?.pay_with_reward && !Boolean(modalLogin.currentUser.token) && (
              <div className="card-container__button">
                <Button
                  title="USAR MI SALDO"
                  disabled={false}
                  type="primary"
                  callbak={handleOpenModalLogin} 
                />
              </div>
            )}

            {invoice?.config?.wallet_connect && (
              <div className="card-container__button">
                <Button
                  title={!wallet 
                    ? "PAGAR CON WALLET CONNECT"
                    : `${wallet.accounts[0].substring(0, 5)}...${wallet.accounts[0].substr(-5)} SALIR`
                  }
                  disabled={false}
                  type="primary"
                  callbak={!wallet ? openWalletConnect : logout} 
                />
              </div>  
            )}
  
            {invoice?.config?.open_wallet && (
              <div className="card-container__button">
                <Button
                  title="ABRIR WALLET"
                  disabled={false}
                  type="primary"
                  callbak={openBTCWallet} 
                />
              </div>
            )}

            <div className="card-container__button">
              <Button
                title="VOLVER"
                disabled={false}
                type="default"
                callbak={backButton} 
              />
            </div>
          </div>

          <LoginModal />

          {loadingCreateReward && <Spinner />}
        </Card>
      </div>

      <Footer />
    </>
  )
}

export default Pay
