import React, { useCallback, useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactTooltip from "react-tooltip";
import { useFormik } from "formik";

import Card from "../../components/Card";
import Select from "../../components/select";
import Input from "../../components/input";
import Button from "../../components/button";
import Modal from "../../components/modal";
import SelectCrypto from "../../components/selectCrypto";

import { useQuery } from '../../hooks';
import { getPaymentAction } from "../../redux/actionsCreators";
import { setLoading } from "../../redux/actions/payments";
import { showModal } from "../../utils/helpers";
import { FIELDS_NAME, initialValues, validationSchema, getCryptoRedData, isBRLPixCryptoRed } from "./utils";
import Constants from "../../utils/constants";
import { getQuotation, getModalSuccessMessage, checkCPF } from "../../service/EntitiesService/paymentsService";

import "./styles/index.scss";

const {
  INPUTS_TYPES: { AMOUNT, TEXT },
  INPUTS_MAX_LENGTH,
  BUTTON: { NEXT },
} = Constants;

const Payments = (props) => {
  const query = useQuery();
  const integrationToken = query.get("token");
  const currencies = useSelector((state) => state.Payments.currencies);
  const [showTooltip, setShowTooltip] = useState(false)
  const [cryptoNetworkShow, setCryptoNetworkShow] = useState('')
  const [quotation, setQuotation] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  const { form: { operation_data: operationData = {} } = {}, prePaiment = {} } = useSelector(
    (state) => state.Payments.config
  );
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    (valuesForm) => {
      const cryptoRedVal = valuesForm[FIELDS_NAME.CRYPTO_RED];
      const { cryptoNetworkId } = getCryptoRedData(cryptoRedVal);
    
      const dataToSend = {
        crypto_network_id: cryptoNetworkId,
        fiat: valuesForm[FIELDS_NAME.CURRENCY],
        amount: valuesForm[FIELDS_NAME.AMOUNT],
      };

      if (isBRLPixCryptoRed(cryptoRedVal)) {
        dataToSend.cpf = valuesForm[FIELDS_NAME.CPF]
      }

      dispatch(getPaymentAction(dataToSend));
    },
    [dispatch]
  );

  const {
    values,
    handleSubmit: submitForm,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    dirty,
    isValid,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: validationSchema(),
    onSubmit: handleSubmit,
  });

  const handleChange = useCallback(
    (fieldName, parseVal) => (value) => {
      const currentVal = parseVal ? parseVal(value) : value;

      setFieldValue(fieldName, currentVal);
    },
    [setFieldValue]
  );

  const parseCryptoRed = useCallback(
    (currentVal) => {
      const { cryptoName } = getCryptoRedData(currentVal);

      setCryptoNetworkShow(cryptoName)

      return currentVal
    },
    []
  );

  const parseCurrency = useCallback(
    (currentVal) => currentVal?.value ?? "",
    []
  );

  const show = showModal(props.location.pathname);

  const cryptoRed = useMemo(() => values[FIELDS_NAME.CRYPTO_RED], [values])
  const cpfValue = useMemo(() => values[FIELDS_NAME.CPF], [values])
  const fiat = useMemo(() => values[FIELDS_NAME.CURRENCY], [values])

  useEffect(() => {
    if (
      Boolean(cryptoRed) &&
      Boolean(fiat)
    ) {
      const { cryptoNetworkId } = getCryptoRedData(cryptoRed);
      setShowTooltip(false);
      getQuotation({ crypto_network_id: cryptoNetworkId, fiat }, integrationToken).then(
        (response) => {
          setQuotation(response?.response?.data?.quotation ?? null)
          setShowTooltip(true)
        }
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cryptoRed, fiat]);

  useEffect(() => {
    getModalSuccessMessage(integrationToken).then(response => {
      setSuccessMessage(response.response.data)
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [isValidCPF, setIsValidCPF] = useState(false);


  useEffect(() => {
    if (cpfValue && cpfValue.length === INPUTS_MAX_LENGTH.CPF) {
      dispatch(setLoading())

      checkCPF(cpfValue).then(response => {
        setFieldTouched(FIELDS_NAME.CPF, true, false);
        setFieldError(FIELDS_NAME.CPF, !response?.response?.data?.result ? 'Not Valid CPF' : undefined)

        setIsValidCPF(response?.response?.data?.result)

        dispatch(setLoading())
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cpfValue])

  return (
    <Card className="payments">
      <div className="payments-container">
        <div className="payments-content">
          <h1 className="payments-ttile">{operationData.title}</h1>
          <div className="form-group">
            <h3 className="payments-labels">Criptomoneda y red de pago</h3>
            <SelectCrypto
              handleCrypto={handleChange(
                FIELDS_NAME.CRYPTO_RED,
                parseCryptoRed
              )}
            />
            {Boolean(showTooltip && quotation )&& (
              <>
                <span
                  data-tip="Recuerda que la cotizacion puede variar al momento de concretar el pago."
                  className="payments-tooltip"
                >
                  1 {cryptoNetworkShow} ≈ {quotation} {fiat}
                </span>

                <ReactTooltip
                  className="tooltip"
                  place="right"
                  type="light"
                  textColor="#6E49AE"
                />
              </>
            )}
          </div>
        </div>
        {isBRLPixCryptoRed(cryptoRed) && (
          <div className="payments-content">
            <div className="form-group">
              <h3 className="payments-labels">Ingrese el CPF</h3>
              <Input
                placeholder=""
                type={TEXT}
                getValue={async (value) => {
                  await setFieldValue(FIELDS_NAME.CPF, value)

                  if (value.length === INPUTS_MAX_LENGTH.CPF) {
                    setFieldError(FIELDS_NAME.CPF, !isValidCPF ? 'Not Valid CPF' : undefined)
                  }
                }}
                error={touched[FIELDS_NAME.CPF] && errors[FIELDS_NAME.CPF]}
                maxLength={INPUTS_MAX_LENGTH.CPF}
                onBlur={async (event) => {
                  await setFieldTouched(FIELDS_NAME.CPF, true);

                  if (event.target.value.length === INPUTS_MAX_LENGTH.CPF) {
                    setFieldError(FIELDS_NAME.CPF, !isValidCPF ? 'Not Valid CPF' : undefined)
                  }
                }}
              />
            </div>
          </div>
        )}
        <div className="payments-content">
          <div className="form-group">
            <h3 className="payments-labels">Seleccione la moneda</h3>
            <Select
              getSelect={handleChange(FIELDS_NAME.CURRENCY, parseCurrency)}
              options={currencies}
              value={prePaiment.exist ? prePaiment.currency_id : ''}
            />
          </div>
        </div>
        <div className="payments-content">
          <div className="form-group">
            <h3 className="payments-labels">Ingrese el monto</h3>
            <Input
              placeholder=""
              type={AMOUNT}
              defaultValue={prePaiment.exist ? prePaiment.amount: ''}
              getValue={handleChange(FIELDS_NAME.AMOUNT, parseFloat)}
            />
          </div>
        </div>
        <div className="payments-content">
          <div className="payments-right__button">
            <Button
              title={NEXT}
              type="primary"
              disabled={!dirty || !isValid}
              callbak={submitForm}
            />
          </div>
        </div>
      </div>
      <Modal status={show.status} show={show.show} message={successMessage} />
    </Card>
  );
};

export default Payments;
