const Constants = {
  PLACEHOLDER: 'ejemplo@ejemplo2.com',
  INPUTS_TYPES: {
    TEXT: 'text',
    EMAIL: 'email',
    AMOUNT: 'amount',
  },
  INPUTS_MAX_LENGTH: {
    CPF: 11,
  },
  BUTTON: {
    NEXT: 'SIGUIENTE'
  },
  MODAL: {
    ERROR: {
      TITLE: 'ALGO FALLÓ',
      SUBTITLE: 'Su solicitud no pudo completarse. Por favor, vuelva a intentarlo.',
    },
    SUCCESS: {
      TITLE: 'OPERACIÓN EXITOSA',
      SUBTITLE: 'Su solicitud se procesó correctamente.',
    }
  },
  PATHS: {
    CANCEL: '/payments/cancel',
    SUCCESS: '/payments/success',
  },
  TYPES: {
    ERROR: 'error',
    SUCCESS: 'success'
  },
  SNACKBAR_TIMEOUT: 5000,
  INVOICE_STATUS: {
    CHECKING: 'checking',
    COMPLETING: 'completing',
    PRE_CONFIRMED: 'pre_confirmed',
    EXPIRING: 'expiring',
    PAID_PARTIAL_AND_EXPIRED: 'paid_partial_and_expired',
    PAID_PARTIAL: 'paid_partial',
    WAITING: 'waiting'
  },
}

export default Constants;
