import {
  setCurrencies,
  setLoading,
  setSnackbar,
  setConfig,
  setCrypto,
  setInvoice,
  setInvoiceStatus,
  setModalLoginShow,
  setModalLoginLoading,
  setModalLoginCurrentUser,
  setRewards,
  setLoadingCreateReward,
  setPaymentLinkInfo,
  setPaymentLinkErrorCreate,
} from '../actions/payments';
import {
  getCurrencies,
  createPay,
  getConfig,
  getInvoice,
  makeRequestTogetStatus,
  saveHashId,
  loginServices,
  getSummaryByTokens,
  createReward,
  getPaymentLinkInfoRequest,
  createPaymentLink,
} from '../../service/EntitiesService/paymentsService';
import { transformCurrencies, buildCurrenciesList } from '../../utils/helpers';

export function getConfigAction() {
  return async (dispatch, getState) => {
    const { token } = getState().Payments;
    dispatch(setLoading());
    const response = await getConfig({}, token);
    if (response && response.code === 200) {
      const { response: { data: {  crypto_networks, fiats, ...restData}, pre_payment } } = response || {};
      const currencies = transformCurrencies(fiats);
      const crypto = buildCurrenciesList(crypto_networks);

      dispatch(setConfig({...restData, prePaiment: pre_payment }));
      dispatch(setCurrencies(currencies));
      dispatch(setCrypto(crypto));
    }
    dispatch(setLoading());
  }
}

export function getCurrenciesAction() {
  return async (dispatch, getState) => {
    dispatch(setLoading());
    const response = await getCurrencies();
    if (response.code === 200) {
      const { response: { data = [] } } = response || {};
      const transformed = transformCurrencies(data);
      const criptoList = buildCurrenciesList(data);
      dispatch(setCrypto(criptoList));
      dispatch(setCurrencies(transformed));
    }
    dispatch(setLoading());
  }
}

export function getPaymentAction(data) {
  return async (dispatch, getState) => {
    const { token } = getState().Payments;
    dispatch(setLoading());
    const response = await createPay(data, token);
    if (response.code >= 200 && response.code <= 209 ) {
      const { response: { external_code } } = response || {};     
      window.location.href = `/pay?token=${external_code}`;
    } else {
      dispatch(setSnackbar({
        open: true,
        message: response.response,
        severity: 'error'
      }))
    }
    dispatch(setLoading());
  }
}

export function getInvoiceData(code) {
  return async (dispatch, getState) => {
    const { token } = getState().Payments;
    dispatch(setLoading());
    const response = await getInvoice(code, token);
    if (response.code >= 200 && response.code <= 209 ) {
      const { response: { data } } = response || {};     
      dispatch(setInvoice(data));
      dispatch(setInvoiceStatus(data.status));  
    
    } else {
      dispatch(setSnackbar({
        open: true,
        message: response.response,
        severity: 'error'
      }))
    }
    dispatch(setLoading());
  }
}

export function getInvoiceStatus(code) {
  return async (dispatch, getState) => {
    const { token } = getState().Payments;
    const response = await makeRequestTogetStatus(code, token);
    if (response.code >= 200 && response.code <= 209 ) {
      const { response: { data } } = response || {};     
      dispatch(setInvoiceStatus(data));     
    
    } else {
      dispatch(setSnackbar({
        open: true,
        message: response.response,
        severity: 'error'
      }))
    }
  }
}

export function saveHash(code, token) {
  return async (dispatch) => {
    console.log(token);
    const response = await saveHashId(code, token);
    if (response.code >= 200 && response.code <= 209 ) { 
      dispatch(setSnackbar({
        open: true,
        message: "Tx Hash generado exitosamente!",
        severity: 'success'
      }))
    
    } else {
      dispatch(setSnackbar({
        open: true,
        message: response.response,
        severity: 'error'
      }))
    }
  }
}

export function getRewards(token) {
  return async (dispatch) => {
    const { code, response } = await getSummaryByTokens(`Bearer ${token}`);

    if (code >= 200 && code <= 209) {
      dispatch(setRewards(response.data));
    } else {
      dispatch(setSnackbar({
        open: true,
        message: 'Ha ocurrido un error al cargar los rewards, intente nuevamente',
        severity: 'error'
      }))
    }
  }
}

export function initializeLoginServices(username, password, closeModalLoginOnSuccess = true) {
  return async (dispatch) => {
    dispatch(setModalLoginCurrentUser({
      token: null,
      error: null,
    }))
    dispatch(setModalLoginLoading(true));


    const responseLogin = await loginServices(username, password);
  
    if (responseLogin.code >= 200 && responseLogin.code <= 209) {
      const token = responseLogin.response.token;

      await dispatch(getRewards(token));
    
      dispatch(setModalLoginCurrentUser({
        token,
        error: null,
      }));

      if (closeModalLoginOnSuccess) {
        dispatch(setModalLoginShow(false));
      }
    } else {
      const errorMessage = responseLogin.code === 401
        ? 'Usuario y/o contraseña incorrecto/s'
        : 'Ha ocurrido un error, intente nuevamente';

      dispatch(setModalLoginCurrentUser({
        token: null,
        error: errorMessage,
      }));
    }

    dispatch(setModalLoginLoading(false));
  }
}

export function logoutServices(closeModalLogin = true) {
  return (dispatch) => {
    dispatch(setModalLoginCurrentUser({
      token: null,
      error: null,
    }));

    if (closeModalLogin) {
      dispatch(setModalLoginShow(false));
    }
  }
}

export function savePaymentReward(
  amount,
  currencyId,
  invoiceToken,
  token,
  callbackSuccess,
) {
  return async (dispatch) => {
    dispatch(setLoadingCreateReward());

    const { code, response } = await createReward(
      amount,
      currencyId,
      invoiceToken,
      `Bearer ${token}`,
    );

    if (code >= 200 && code <= 209) {
      await dispatch(getRewards(token));
      
      dispatch(setInvoice(response.data));
      dispatch(setInvoiceStatus(response.data.status));

      callbackSuccess();

      dispatch(setSnackbar({
        open: true,
        message: 'Rewards usados satisfactoriamente',
        severity: 'success'
      }))
    } else {
      dispatch(setSnackbar({
        open: true,
        message: 'Ha ocurrido un error, intente nuevamente',
        severity: 'error'
      }))
    }

    dispatch(setLoadingCreateReward());
  }
}

export function getPaymentLinkInfo(paymentLinkToken, integrationToken) {
  return async (dispatch) => {
    dispatch(setLoading());
    const { code, response } = await getPaymentLinkInfoRequest(paymentLinkToken, integrationToken);
    if (code >= 200 && code <= 209) {
      dispatch(setPaymentLinkInfo(response.data));
    } else {
      dispatch(setPaymentLinkErrorCreate(true));
    }
    dispatch(setLoading());
  }
}

export function savePaymentLink(paymentLinkToken, integrationToken, data) {
  return async (dispatch) => {
    dispatch(setLoading());
    const { code, response } = await createPaymentLink(paymentLinkToken, integrationToken, data);
    if (code >= 200 && code <= 209 ) {
      window.location.href = `/pay?token=${response.external_code}`;
    } else {
      dispatch(setSnackbar({
        open: true,
        message: response.response,
        severity: 'error'
      }))
    }
    dispatch(setLoading());
  }
}
