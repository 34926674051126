import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import { validateInputValue } from '../../utils/helpers';
import './styles/index.scss';

const Input = ({
	getValue,
	placeholder,
	type,
	defaultValue,
	disabled = false,
	error = false,
	maxLength,
	onBlur,
}) => {
	const [inputValue, setInputValue] = useState('');

	const handleOnChange = event => {
		const { value } = event.target;
		if (type && value && (isNaN(value) || !validateInputValue(type, parseInt(value)))) {
			return false;
		}
		setInputValue(value);
		getValue(value);
	};

	useEffect(() => {
		if (defaultValue !== undefined) {
			handleOnChange({ target: { value: defaultValue }});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultValue])

	const inputClass = classNames('payments-input', {
		'error-input': (inputValue && !validateInputValue(type, inputValue)) || error
	})

  return (
		<input
			value={inputValue}
			onChange={handleOnChange}
			className={inputClass}
			placeholder={placeholder}
			disabled={disabled}
			maxLength={maxLength}
			onBlur={onBlur}
		/>
	);
}

export default Input;
